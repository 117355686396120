<template>
  <div class="home" v-if="$root.info">
      HOME

  </div>



</template>

<script>
import { loadYmap } from 'vue-yandex-maps'

export default {
  data: function(){ return {
      current_video:0,
      current_image:0,
      win:null
  }},
  
  mounted: function() {
      var _this = this;
      this.$nextTick(function () {
          // Код, который будет запущен только после
          // отрисовки всех представлений
          _this.ymap();
        })
  },
  
  methods:{
      history_click: function(h){
         if( h.page ){
             if( this.$root.info.page_ids[ h.page ] ){
                    this.$router.push({ path: 'v-page/'+this.$root.info.page_ids[ h.page ] });
                    return;
             } 
         }else if( h.link ){
             h.href = h.link.replace( /\{terminal\.coords\}/,  (this.$root.terminal.long + ',' + this.$root.terminal.lat) );
             console.log(h.href);
         

             var newWin = window.open(h.href, h.name, "width=1200,height=800,top=70,left=50menubar=no,location=no,resizable=no,scrollbars=yes,status=no" )
             newWin;
         }

      },
      nextVideo: function(){
         if(this.$root.info.video.length == 1) return;
         if( this.current_video + 1 <  this.$root.info.video.length ){
             this.current_video++;
         }else{
             this.current_video = 0;
         }
      },
      
      img_prev: function(){
         if( this.current_image > 0 ){
             this.current_image--;
         }else{
             this.$root.info.images.length - 1;
         }
      },
      img_next: function(){
         if( this.current_image + 1 <  this.$root.info.images.length ){
             this.current_image++;
         }else{
             this.current_image = 0;
         }
      },
      
      ymap: function(){
          if( !this.$refs.ymap ){
               return setTimeout( this.ymap, 50);
          }
          var vueEl = this;
          loadYmap({apiKey: this.$root.ymapKey, debug: true }).then( function(){
      
            var ymaps = window.ymaps;
 
            var myMap = new ymaps.Map( vueEl.$refs.ymap, {
                  center: [vueEl.$root.terminal.lat, vueEl.$root.terminal.long],
                  zoom: 17
              }, {
                  searchControlProvider: 'yandex#search',
                   suppressMapOpenBlock: true
              });

            var myGeoObject = new ymaps.GeoObject({
                  // Описание геометрии.
                  geometry: {
                      type: "Point",
                      coordinates: [vueEl.$root.terminal.lat, vueEl.$root.terminal.long]
                  },
                  // Свойства.
                  properties: {
                      // Контент метки.
                      iconContent: 'Вы здесь',
                      hintContent: vueEl.$root.terminal.name
                  }
              }, {
                  // Опции.
                  // Иконка метки будет растягиваться под размер ее содержимого.
                  preset: 'islands#blackStretchyIcon',
                  iconColor: '#9a6c36'
      
              })
            myMap.geoObjects.add(myGeoObject)
          });
      }
  }

}   
</script>


<style>
   .home .video { position: relative }
   .down { position: absolute; bottom:0; right:0; left:0; height: 20% }

   .home_info { flex: 1 1 35% }
   .home .images { flex: 1 1 65%; position: relative }
   .home .images .back, .home .images .next { position: absolute; top:0; bottom:0; width:50%; height:100%; display:flex; align-items:center;  opacity:0.8; color:var(--primary); padding:0 15px }
   .home .images .back { left:0; right:50%; justify-content:flex-start; }
   .home .images .next {  right:0; left:50%; justify-content:flex-end; }
   .home .images .back:hover, .home .images .next:hover { opacity:1 }
   
   .carousel-caption h3 { color: #fff; font-size: 1.2rem}
   
   #ymap { width: 100%; height: 700px; padding: 0; margin: 0; }
</style>