<template>

<div class="card p-4" style="max-width:500px; margin: 30px auto">

  <div v-if="confirmRegistration" class="alert alert-success">
        <small>На указанный вами адрес электронной почты отправлено сообщение с ссылкой на подтверждение регистрации.</small> 
        <br>
        Для продолжения регистрации пожалуйста перейдите по ссылке в письме.
        <div><b-link href="#" variant="light" @click="confirmRegistration=false">Отмена</b-link></div>
  </div>
  
  <div v-else>
  
  <h1>Авторизация</h1>

  <div class="form-group">
    <label for="InputEmail1">Email</label>
    <b-form-input v-model.lazy="email" type="email" :state="!invalid_email" id="InputEmail1"></b-form-input>
    <b-form-invalid-feedback :state="!invalid_email">
          {{ invalid_email }}
    </b-form-invalid-feedback>
  </div>

<div role="tablist">

      <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
        <div class="form-group Password">
          <label for="InputPassword">Пароль</label>
          <b-input v-model="pass" :state="!invalid_pass" type="password" class="form-control" id="InputPassword" ></b-input>
          <b-form-invalid-feedback :state="!invalid_pass">
                {{ invalid_pass }}
          </b-form-invalid-feedback>
          
        </div>
        <b-form-invalid-feedback :state="errorAuth" class="alert alert-danger my-2">
             Email или пароль указаны неверно 
        </b-form-invalid-feedback>
        
        <button v-on:click="auth" class="btn btn-primary" v-if="!invalid_pass && !invalid_email">Войти</button>

      
      </b-collapse>

      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" class="card card-body">
        На указанный Вами адрес электронной почты будет отправлена ссылка для авторизации. 
        <button type="button" v-on:click="repair" class="btn btn-primary">Восстановить</button>
      </b-collapse>


      <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel" class="card card-body">
        На указанный Вами адрес электронной почты будет отправлена ссылка для регистрации.
        <button type="button"  v-on:click="create" class="btn btn-primary">Зарегистрироваться</button>
      </b-collapse>

      <br>
             <div><b-link block href="#" v-b-toggle.accordion-1 variant="light" class="acc">У меня есть пароль</b-link></div>
             <div><b-link block href="#" v-b-toggle.accordion-2 variant="light" class="acc">Забыли пароль?</b-link></div>
             <div><b-link block href="#" v-b-toggle.accordion-3 variant="light" class="acc">Еще нет учетной записи?</b-link></div>
  </div>

  
  </div>
  
</div>

</template>

<script>
// @ is an alias to /src


export default {
  data: function(){
      return {
                    email: '',
                    pass: '',
                    errorAuth: true,
                    confirmRegistration: false,
                    
                    invalid_pass: false,
                    invalid_email:false
      }
  },
  created: function(){
                    if( sessionStorage.confirmRegistration && sessionStorage.confirmRegistration=='1' ) this.confirmRegistration = true;
                    else{ 
                        this.confirmRegistration = false;
                    }
  },
  watch: {
       pass: function(v){
           this.invalid_pass = v.length > 6 && v.length < 13  ?  false : 'Пароль должен быть от 6 до 12 символов длинной'
       },
       email: function(v){
          var reg = /^([a-z0-9_\-\.])+\@([a-z0-9_\-\.])+\.([a-z]{2,4})$/i;
          this.invalid_email = reg.test( v )  ?  false : 'Введите корректный адрес электронной почты латинскими символами.'
       }
  },
  methods: {
                    checkEmail: function(){
                    
                    },
                    auth: function(){
                         var _this = this;
                         this.$root.set('auth', 'login', {email:this.email, pass:this.pass}).then( function(data){
                               if( data.error ){
                                   _this.errorAuth = false;
                               }else{
                                   _this.errorAuth = true;
                                   sessionStorage.userid = data.id;
                                   sessionStorage.token = data.token;
                                   _this.$root.action('main');
                               }
                         });
                    },
                    repair: function(){
                         var _this = this;
                         this.$root.set( 'auth', 'create', { email:this.email } ).then( function(data){
                             if( data.success ){ 
                                sessionStorage.confirmRegistration = '1';
                                _this.confirmRegistration = true;
                                //dx.action('confirm');
                              }
                         });

                    },

                    create: function(){
                         var _this = this;
                         
                         this.$root.set('auth', 'create', { email:this.email }).then( function(data){
                             if( data.success ){ 
                                 sessionStorage.confirmRegistration = '1';
                                 _this.confirmRegistration = true;
                                 //dx.action('confirm');
                              }
                          })
                    }
               },


}
</script>
