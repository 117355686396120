<template>

<div v-if="result" id="ticket">
      {{ result.entity_no }} от {{ result.createdtime }}
                        <span v-if="result.status=='Planned'" class="badge badge-primary badge-pill">В обработке</span>
                        <span v-else-if="result.status=='In Progress'" class="badge badge-primary badge-pill">Принято в работу</span>
                        <span v-else-if="result.status=='Wait' || result.status=='Wait_Response'" class="badge badge-primary badge-pill">В ожидании</span>
                        <span v-else-if="result.status=='New'" class="badge badge-warning badge-pill">Новое</span>
                        <span v-else-if="result.status=='Closed'" class="badge badge-secondary badge-pill">Закрыто</span>
                        <span v-else-if="result.status=='Accepted'" class="badge badge-secondary badge-pill">Закрыто</span>
      <hr>    
      <div class="card-body">
          {{ result.description }}
      </div>

      <div v-if="result.solution!=''" class="card">
      <p>Решение:</p>
      <div class="card-body warning">
          {{ result.solution }}
      </div> </div>
      

      <div class="comments" v-if="result.comments">
          <p>Комментарии:</p>
          <div v-for="comment in result.comments" :key="comment.modcommentsid">
              <div class="card" :class="(comment.quest==1?'main':'re')">
              <div class="card-body" >
                  <p class="text-muted">{{ comment.createdtime }}</p>
                  <div v-html="comment.commentcontent"></div>
              </div>
              </div>
          </div>
      </div>
      <hr>
      <div v-if="result.status_code==1">
          <div v-if="add_comment">
               <form method="post" enctype="multipart/form-data" ref="form" onsubmit="return false">
               Ваш комментарий:
               <textarea class="form-control" v-model="new_comment" name="comment"></textarea>
                <b-form-file v-model="file" placeholder="файл..." drop-placeholder="Поместите здеь..." browse-text="Выбрать" name="files[]"></b-form-file>
               </form>
               <button type="button" class="btn btn-primary" @click="saveComment">Отправить</button>
          </div>
          <div v-else>
              <button class="btn btn-primary" @click="add_comment=true">Добавить комментарий</button>
              <button class="btn btn-success ml-2" @click="close_ticket">Проблема решена (закрыть обращение)</button>
          </div>    
      </div>

  </div>

</template>  
  
<script>
export default {
    props:['id'],
    data:function(){ return{ 
        result:null,
        new_comment:'',
        add_comment:false,
        file:null
    }},
    created: function(){
      this.$root.set('main', 'ticket', {id:this.id} ).then( this.load );
    },
    methods:{
       load: function(data){
              console.log( 'load', data);
              this.result = data.result;
       },
                                    saveComment: function(){
                                         if( this.result.id ){
                                              var formData = new FormData(this.$refs.form); 
                                              formData.append('id', this.result.ticketid)
                                              this.$root.set('main', 'saveComment',  formData, 'FORM').then(this.onSaveComment );
                                         }else{
                                             console.log('ERROR no id');
                                         }
                                    },
                                    onSaveComment: function(data){
                                         this.result.comments.push(data.result);
                                         this.new_comment = '';
                                         this.add_comment = false;
                                    },
                                    close_ticket: function(){
                                         this.$root.set('main', 'close_ticket', {id:this.result.ticketid} ).then(this.onCloseTicket);
                                    },
                                    onCloseTicket: function(data){
                                         this.$bvModal.hide('modal');
                                         this.$emit('closeTicket', data.result.ticketid );
                                    }
    }, 
}
</script>
