<template>

  <div>
      <form method="post" enctype="multipart/form-data" ref="form" onsubmit="return false">
      
      <div v-if="crmdata.products.length>0" class="card-body">
           
           Продукт:
           <b-form-select v-model="productid" :state="product_valid" name="product" >
              <option value="0">Выберите продукт с который связан ваш вопрос</option>
              <option value="-1">Общий вопрос</option>
              <optgroup  label="Ваши продукты" >
                <option v-for="product in crmdata.products" :key="product.productid" :value="product.productid">
                  - {{ product.productname }}
                </option>
              </optgroup>
           </b-form-select>
          {{ productid }}
          <b-form-invalid-feedback :state="!productid==0">
              Выберите продукт к котору относится ваш вопрос
          </b-form-invalid-feedback>
      </div>
      
      <div class="card-body">
           Тема:
           <b-form-input  v-model="title" name="title" :state="title_valid"></b-form-input>
      </div>

      
      <div class="card-body">
           Заявка:
           <b-form-textarea v-model="text" name="text" :state="text_valid" rows="10"></b-form-textarea>

      </div>
      
      
      <div class="card-body">      
          <b-form-file v-model="file" placeholder="файл..." drop-placeholder="Поместите здеь..." browse-text="Выбрать" name="files[]"></b-form-file>

      </div>

      </form>
      
      <button type="button" class="btn btn-primary" @click="saveTicket">Отправить</button>
      

  </div>
  
</template>  
  
<script>
export default {
      props:[ 'crmdata'],
      
      data:function(){
                                     return {text:'', 
                                     title:'', 
                                     productid: this.crmdata.products.length==0 ? -1 : null, 
                                     product_valid:null,
                                     title_valid:null,
                                     text_valid:null,
                                     error: null,
                                     file:null
                                      };
                                },
                                methods: {
                                     saveTicket: function(){
                                          console.log('saveTicket');
                                          this.product_valid = this.crmdata.products.length==0 || this.productid!=0;
                                          this.title_valid = this.title.length>0;
                                          this.text_valid = this.text.length>0;
                                          if( this.product_valid && this.title_valid && this.text_valid){
                                              var formData = new FormData(this.$refs.form); 
                                              this.$root.set('main', 'newTicket',  formData, 'FORM').then(this.onSaveTicket );
                                              //this.$root.set('main', 'newTicket', { title:this.title, text:this.text } ).then( this.onSaveTicket )
                                          }else{
                                              this.error = true;
                                          }
                                     },
                                     onSaveTicket: function(data){
                                         this.$bvModal.hide('modal');
                                         this.$emit('upData', data);
                                     }
                                },

}

</script>
