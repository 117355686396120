<template>
  <div id="app">

<nav class="navbar justify-content-between navbar-dark bg-dark navbar-expand-sm">
    <div class="flex">
      <div>
      <a class="navbar-brand mr-0 mr-md-2 p-2 rounded border border-secondary text-secondary" href="/">GetCall</a> 
      Личный кабинет
      </div>
      <button @click="out()" type="button" class="btn btn-outline-light">Выход</button>
    </div>
</nav> 

    <div id="content" class="flex-grow">
             <router-view />
    </div>


</div>
</template>

<style src="./assets/style.css"></style>

<script>
export default {
  name: 'App',
  data: function(){ 
   return {
      sidebarShow: localStorage.getItem('sidebarShow'),
   }
  },

  methods: {
    out: function(){
        sessionStorage.userid = null;
        this.$root.action('auth');
    }
  },   
}
</script>