<template>
<div class="m-3">
    <b-alert
      :show="alert.text!=false"
      dismissible
      fade
      :variant="alert.variant"
      @dismissed="alert.text=false"
    >{{ alert.text }}</b-alert>
    
    <b-tabs pills card content-class="mt-3" justified v-model="tabIndex">
      <!--
      <b-tab :title="'Мои заказы'" active>
      <template slot="title">
        Мои заказы <strong v-if="orders_count>0" class="badge badge-warning badge-pill">{{ orders_count }}</strong>
      </template>
      <b-card-text>
      Tab Contents 1
      </b-card-text></b-tab>
      -->
      
      <b-tab>
          <template slot="title">
            Поддержка <strong v-if="help_count>0" class="badge badge-warning badge-pill">{{ help_count }}</strong>
          </template>
          <div class="card-text">
              <div v-if="crmdata.tickets_count">
                  Активных обращений: {{ crmdata.tickets_count }}.

                  <b-list-group>
        
                    <b-list-group-item  v-for="item in tickets" v-bind:key="item.ticketid" href="#" @click="showTicket(item.ticketid)" :variant="item.status=='Wait_Response'?'warning':'light'" class="d-flex justify-content-between align-items-center">
                        <span v-if="item.title.length>0"> {{ item.title }}</span><span v-else>Без темы</span>
                        <div>

                        <span class="small">тикет от {{ item.createdtime }}</span> &nbsp;
                        <span v-if="item.status=='Planned'" class="badge badge-primary badge-pill">В обработке</span>
                        <span v-if="item.status=='In Progress'" class="badge badge-primary badge-pill">Принято в работу</span>
                        <span v-if="item.status=='Wait'" class="badge badge-primary badge-pill">В ожидании</span>
                        <span v-if="item.status=='New'" class="badge badge-warning badge-pill">Новое</span>
                        <span v-if="item.status=='Closed'" class="badge badge-secondary badge-pill">Закрыто</span>
                        <span v-if="item.status=='Accepted'" class="badge badge-secondary badge-pill">Закрыто</span>
                        </div>
                    </b-list-group-item>
    
                    <b-list-group-item href="#" variant="light">-- Архив</b-list-group-item>
                  </b-list-group>
              </div>
              
              <button class="btn btn-primary mt-5" @click="newTicket()">Создать обращение</button>
          </div>
      </b-tab>
      
      
      <b-tab title="Реквизиты">
          <div class="card-text">
                <div v-if="user_data">
                  

                   
                  <div v-if="user_data.address">
                     <h2>Адрес</h2>
                     <div  v-for="field in user_data.address" :key="field.fieldname">
                        <b-form-group :id="'input-group-'+field.fieldname"
                                :label="field.label+':'"
                                :label-for="field.fieldname"
                                :description="field.helpinfo">
                          <b-input v-model="field.value"  type="text" class="form-control" :id="field.fieldname" ></b-input>
                        </b-form-group>
                     </div>
                   </div>
                   
                   <div v-if="user_data.company">  
                     <h2>Реквизиты</h2>
                     <div  v-for="field in user_data.company" :key="field.fieldname">
                        <b-form-group :id="'input-group-'+field.fieldname"
                                :label="field.label+':'"
                                :label-for="field.fieldname"
                                :description="field.helpinfo">
                          <b-input v-model="field.value"  type="text" class="form-control" :id="field.fieldname" ></b-input>
                        </b-form-group>
                     </div>
                   </div>
                  
                </div>
          </div>

                  
                  <div v-if="user_data.base">

                     <div  v-for="field in user_data.base" :key="field.fieldname">
                        <b-form-group :id="'input-group-'+field.fieldname"
                                :label="field.label+':'"
                                :label-for="field.fieldname"
                                :description="field.helpinfo">
                          
                          <b-form-select v-if="field.fieldtype=='picklist'" v-model="field.value" :options="field.options"></b-form-select>
                          <b-input v-else v-model="field.value"  type="text" class="form-control" :id="field.fieldname" ></b-input>
                          
                          
                        </b-form-group>
                     </div>
                   </div>
                   
                  <div v-if="user_data.contact">
                     <h4>Дополнительные контакты</h4>
                     <div  v-for="field in user_data.contact" :key="field.fieldname">
                        <b-form-group :id="'input-group-'+field.fieldname"
                                :label="field.label+':'"
                                :label-for="field.fieldname"
                                :description="field.helpinfo">
                          <b-input v-model="field.value"  type="text" class="form-control" :id="field.fieldname" ></b-input>
                        </b-form-group>
                     </div>
                   </div>
                   
                   <button class="btn btn-primary mt-5" @click="userDataSave">Сохранить</button>

      </b-tab>
      

    </b-tabs>


    <b-modal id="modal" hide-footer size="xl">
    <template slot="modal-title">
      {{ modal_title }} 
    </template>
    <div class="d-block" v-if="modal_type=='ticket' && ticketid">
        <tiket @closeTicket="closeTicket" :id="ticketid"></tiket>
    </div>
    <div class="d-block" v-else-if="modal_type=='new_ticket'">
        <newtiket  :crmdata="crmdata" @upData="upData"></newtiket>
    </div>
    </b-modal>
</div>



</template>

<script>


export default {
  data: function(){ return {
                    tabIndex:0,
                    isCrm:false,
                    orders_count: 0,
                    help_count: 0,
                    modal_title: '',
                    modal_type: '',
                    ticketid:0,
                    crmdata:{},
                    tickets: [],
                    ticket: {},
                    user_data:{},
                    currentModal: null,
                    alert: { text:false, variant:'success' }
  }},
  
  created: function(){

                    var _this = this;
                    this.$root.set('main', 'index', null ).then( function(data){
                            if( data.error ){
                                if( data.error == 'nocrmid' ){
                                     _this.tabIndex = 2;
                                     _this.userShema( data.fields );
                                }     
                            }else{
                                _this.isCrm = true;
                                _this.crmdata = data.result;
                                //var ts = [];
                                for( var t in data.result.tickets){
                                   console.log(data.result.tickets[t]);
                                   _this.tickets.push(data.result.tickets[t])
                                }
                                _this.tickets.reverse();
                                _this.tabIndex = 0;
                                //_this.user_data = { email: data.result.accinfo.email.value };
                                _this.userShema( data.result.accinfo );
                            }
                    })

               },
  
  methods:{
     
                    out: function(){
                        sessionStorage.removeItem('confirmRegistration');
                        this.$root.set('auth', 'out');
                        this.$root.action('auth');
                    },



                    upData: function(data){
                           for( var k in data.result ){
                                this.crmdata[k] = data.result[k];
                           }
                    },

                    userShema:function(data){
                          var shema = {
                             base :  [ 'firstname', 'lastname' ],
                             company : [ 'orgform', 'accountname', 'fullname', 'inn', 'kpp', 'ogrn', 'bik', 'bankname', 'bankaccount', 'corraccount', 'fiorod', 'podpis', 'poustavu', 'industry' ],
                             address : [ 'country', 'okrug', 'state', 'city', 'street', 'pobox' ],
                             contact : [ 'email2', 'phone' ]
                          }
                          for( var k in shema ){
                               this.user_data[ k ] = [];
                               for( var i in shema[k] ){
                                     var f =  shema[k][i];
                                     if(data[f]){
                                        var td = data[f].typeofdata.split('~');
                                        data[f].typeofdata = td;
                                        data[f].require = false;
                                        if( td[1] && td[1]=='M' ){
                                              data[f].require = true;
                                        }
                                        this.user_data[ k ].push( data[f] ); 
                                     }else{
                                        this.user_data[ k ].push( null );
                                     }   
                               }
                          }
                    },
                    userDataCheck: function(){
                    
                    },
                    userDataSave: function(){
                          var p = {}, isData = false;
                          
                          for( var section in this.user_data ){ 
                              for( var k in this.user_data[section] ){
                                  var d =  this.user_data[section][k];
                                  console.log(k, d);
                                  if( d.value && d.value.length>0 ){
                                       isData = true;
                                  }else{
                                       d.value = null;
                                  }
                                  p[d.fieldname] =  d.value;
                              } 
                          }
                          if( isData ){
                              var _this = this;
                              this.$root.set('main', 'user_data_save', p ).then( function(data){
                                  if(!data.error) _this.alert.text = 'Данные сохранены';
                              });
                          }
                    },
                    
                    newTicket: function(){
                           this.modal_title = 'Обращение';
                           this.modal_type = 'new_ticket';
                           this.$bvModal.show('modal');
                    },
                    

                    closeTicket: function(ticketid){
                           this.crmdata.tickets[ ticketid ].status = 'Closed';
                    },
                    
                    showTicket: function(id){
                           var t = this.crmdata.tickets[ id ];
                           this.ticketid = id;
                           this.modal_title = t.title;
                           this.modal_type = 'ticket';
                           this.$bvModal.show('modal');                 
                    },
                    
                    

  }

}   
</script>


<style>
   .home .video { position: relative }
   .down { position: absolute; bottom:0; right:0; left:0; height: 20% }

   .home_info { flex: 1 1 35% }
   .home .images { flex: 1 1 65%; position: relative }
   .home .images .back, .home .images .next { position: absolute; top:0; bottom:0; width:50%; height:100%; display:flex; align-items:center;  opacity:0.8; color:var(--primary); padding:0 15px }
   .home .images .back { left:0; right:50%; justify-content:flex-start; }
   .home .images .next {  right:0; left:50%; justify-content:flex-end; }
   .home .images .back:hover, .home .images .next:hover { opacity:1 }
   
   .carousel-caption h3 { color: #fff; font-size: 1.2rem}
   
   #ymap { width: 100%; height: 700px; padding: 0; margin: 0; }
</style>