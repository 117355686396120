<template>
<div class="m-3">
    <b-alert
      :show="alert.text!=false"
      dismissible
      fade
      :variant="alert.variant"
      @dismissed="alert.text=false"
    >{{ alert.text }}</b-alert>
    

      
     <h1>Настройка</h1> 


                <div v-if="user_data">
                  

                   
                  <div v-if="user_data.address">
                     <h2>Адрес</h2>
                     <div  v-for="field in user_data.address" :key="field.fieldname">
                        <b-form-group :id="'input-group-'+field.fieldname"
                                :label="field.label+':'"
                                :label-for="field.fieldname"
                                :description="field.helpinfo">
                          <b-input v-model="field.value"  type="text" class="form-control" :id="field.fieldname" ></b-input>
                        </b-form-group>
                     </div>
                   </div>
                   
                   <div v-if="user_data.company">  
                     <h2>Реквизиты</h2>
                     <div  v-for="field in user_data.company" :key="field.fieldname">
                        <b-form-group :id="'input-group-'+field.fieldname"
                                :label="field.label+':'"
                                :label-for="field.fieldname"
                                :description="field.helpinfo">
                          <b-input v-model="field.value"  type="text" class="form-control" :id="field.fieldname" ></b-input>
                        </b-form-group>
                     </div>
                   </div>
                    

                </div>


                  
                  <div v-if="user_data.base">

                     <div  v-for="field in user_data.base" :key="field.fieldname">
                        <b-form-group :id="'input-group-'+field.fieldname"
                                :label="field.label+':'"
                                :label-for="field.fieldname"
                                :description="field.helpinfo">
                          
                          <b-form-select v-if="field.fieldtype=='picklist'" v-model="field.value" :options="field.options"></b-form-select>
                          <b-input v-else v-model="field.value"  type="text" class="form-control" :id="field.fieldname" ></b-input>
                          
                          
                        </b-form-group>
                     </div>
                   </div>
                   
                  <div v-if="user_data.contact">
                     <h4>Дополнительные контакты</h4>
                     <div  v-for="field in user_data.contact" :key="field.fieldname">
                        <b-form-group :id="'input-group-'+field.fieldname"
                                :label="field.label+':'"
                                :label-for="field.fieldname"
                                :description="field.helpinfo">
                          <b-input v-model="field.value"  type="text" class="form-control" :id="field.fieldname" ></b-input>
                        </b-form-group>
                     </div>
                   </div>
                   
                   <button class="btn btn-primary mt-5" @click="userDataSave">Сохранить</button>

      


</div>



</template>

<script>


export default {
  data: function(){ return {
                    tabIndex:0,
                    isCrm:false,
                    orders_count: 0,
                    help_count: 0,
                    modal_title: '',
                    modal_type: '',
                    ticketid:0,
                    crmdata:{},
                    tickets: {},
                    ticket: {},
                    user_data:{},
                    currentModal: null,
                    alert: { text:false, variant:'success' }
  }},
  
  created: function(){

                    var _this = this;
                    this.$root.set('main', 'index', null ).then( function(data){
                            if( data.error ){
                                if( data.error == 'nocrmid' ){
                                     _this.tabIndex = 2;
                                     _this.userShema( data.fields );
                                }     
                            }else{
                                _this.isCrm = true;
                                _this.crmdata = data.result;
                                _this.tabIndex = 0;
                                _this.user_data = { email: data.result.accinfo.email.value };
                                _this.userShema( data.result.accinfo );
                            }
                    })

               },
  
  methods:{
     
                    out: function(){
                        sessionStorage.removeItem('confirmRegistration');
                        this.$root.set('auth', 'out');
                        this.$root.action('auth');
                    },



                    upData: function(data){
                           for( var k in data.result ){
                                this.crmdata[k] = data.result[k];
                           }
                    },

                    userShema:function(data){
                          var shema = {
                             base :  [ 'firstname', 'lastname' ],
                             company : [ 'orgform', 'accountname', 'fullname', 'inn', 'kpp', 'ogrn', 'bik', 'bankname', 'bankaccount', 'corraccount', 'fiorod', 'podpis', 'poustavu', 'industry' ],
                             address : [ 'country', 'okrug', 'state', 'city', 'street', 'pobox' ],
                             contact : [ 'email2', 'phone' ]
                          }
                          for( var k in shema ){
                               this.user_data[ k ] = [];
                               for( var i in shema[k] ){
                                     var f =  shema[k][i];
                                     if(data[f]){
                                        var td = data[f].typeofdata.split('~');
                                        data[f].typeofdata = td;
                                        data[f].require = false;
                                        if( td[1] && td[1]=='M' ){
                                              data[f].require = true;
                                        }
                                        this.user_data[ k ].push( data[f] ); 
                                     }else{
                                        this.user_data[ k ].push( null );
                                     }   
                               }
                          }
                    },
                    userDataCheck: function(){
                    
                    },
                    userDataSave: function(){
                          var p = {}, isData = false;
                          for( var section in this.user_data ){ 
                              for( var k in this.user_data[section] ){
                                  var d =  this.user_data[section][k];
                                  console.log(k, d);
                                  if( d.value && d.value.length>0 ){
                                       isData = true;
                                  }else{
                                       d.value = null;
                                  }
                                  p[d.fieldname] =  d.value;
                              } 
                          }
                          if( isData ){
                              var _this = this;
                              this.$root.set('main', 'user_data_save', p ).then( function(data){
                                  if(!data.error) _this.alert.text = 'Данные сохранены';
                              });
                          }
                    },
                    
                    newTicket: function(){
                           this.modal_title = 'Обращение';
                           this.modal_type = 'new_ticket';
                           var products = this.crmdata.products;
                           var _parent = this;
                           this.currentModal = {
                                template: '#'+this.modal_type+'Modal',
                                data:function(){
                                     return {text:'', 
                                     title:'', 
                                     productid:0, 
                                     product_valid:null,
                                     title_valid:null,
                                     text_valid:null,
                                     products:products,
                                     error: null,
                                      };
                                },
                                methods: {
                                     saveTicket: function(){
                                          console.log('saveTicket');
                                          this.product_valid = this.products.length==0 || this.productid!=0;
                                          this.title_valid = this.title.length>0;
                                          this.text_valid = this.text.length>0;
                                          if( this.product_valid && this.title_valid && this.text_valid){
                                              this.$root.set('main', 'newTicket', { title:this.title, text:this.text } ).then( this.onSaveTicket )
                                          }else{
                                              this.error = true;
                                          }
                                     },
                                     onSaveTicket: function(data){
                                         this.$bvModal.hide('modal');
                                         _parent.upData(data);
                                     }
                                },
                                
                           }
                           this.$bvModal.show('modal');
                    },
                    

                    showModal: function(type, id){
                          // console.log(this.crmdata.tickets);
                           this.$root.set('main', type, {id:id} ).then( this.loadModal );
                    },
                    
                    
                    showTicket: function(id){
                           var t = this.crmdata.tickets[ id ];
                           this.ticketid = id;
                           this.modal_title = t.title;
                           this.modal_type = 'ticket';
                           this.showModal('ticket',id);                   
                    },
                    
                    
                    loadModal: function(data){
                        data = JSON.parse(JSON.stringify(data));
                        var _this = this;
                        if(this.modal_type == 'ticket' ){
                           data.new_comment = '';
                           data.add_comment = false;
                          this.ticket = data;
                           this.currentModal = {
                                template: '#'+this.modal_type+'Modal',
                                data:function(){
                                     if(!data.result.comments) data.result.comments = [];
                                     return data;
                                },
                                
                                methods: {
                                    close_ticket: function(){
                                         this.$root.set('main', 'close_ticket', {id:this.result.ticketid} ).then( this.onCloseTicket );
                                    },
                                    comment_add: function(){
                                         
                                         this.add_comment=true;
                                         console.log( 'comment_add', this.add_comment);
                                    },
                                    saveComment: function(){
                                         if( this.result.id ){
                                             var saveData = {id:this.result.ticketid,comment:this.new_comment};
                                             this.$root.set('main', 'saveComment', saveData, this.onSaveComment );
                                         }else{
                                             console.log('ERROR no id');
                                         }
                                    },
                                    onSaveComment: function(){
                                         this.result.comments.push({commentcontent:this.new_comment, quest:1});
                                         this.new_comment = '';
                                         this.add_comment = false;
                                    },
                                    onCloseTicket: function(data){
                                         this.$bvModal.hide('modal');
                                         console.log(_this.tickets);
                                         _this.crmdata.tickets[ data.result.ticketid ].status = 'Closed'; 
                                    }
                                    
                                }
                           }
                           
                        }
                         
                        this.$bvModal.show('modal');
                        
                    }
  }

}   
</script>


<style>
   .home .video { position: relative }
   .down { position: absolute; bottom:0; right:0; left:0; height: 20% }

   .home_info { flex: 1 1 35% }
   .home .images { flex: 1 1 65%; position: relative }
   .home .images .back, .home .images .next { position: absolute; top:0; bottom:0; width:50%; height:100%; display:flex; align-items:center;  opacity:0.8; color:var(--primary); padding:0 15px }
   .home .images .back { left:0; right:50%; justify-content:flex-start; }
   .home .images .next {  right:0; left:50%; justify-content:flex-end; }
   .home .images .back:hover, .home .images .next:hover { opacity:1 }
   
   .carousel-caption h3 { color: #fff; font-size: 1.2rem}
   
   #ymap { width: 100%; height: 700px; padding: 0; margin: 0; }
</style>